.keyContainer {
    align-self: center;
    width: 1000px;
    display: flex;
    justify-content: flex-end;
}

.keyInnerContainer {
    width: 210px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px
}

.key {
    display: flex;
    align-items: center;
}