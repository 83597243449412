/* Footer: Grid Row 3 */
.footer {
    grid-row: 3;
    background-color: #ebebeb;
    display: block;
    text-align: center;
}

.copyright, .privacyLink {
    font-size: 12px;
    line-height: 1.5em;
}

.privacyLink {
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1em;
    }

    .copyright, .privacyLink {
        line-height: 2em;
    }
}