.buttonContainer {
    display: flex;
    justify-content: center;
}

.buttonContainer > button {
    cursor: pointer;
    padding: 0.25em 1.5em;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
}

.searchAgain {
    margin: 5px 0 10px;
    background-color: #d6d6d6;
    color: black;
}

.returnButton {
    background-color: #5f5f60;
    color: #ffffff;
}