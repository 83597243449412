.dividerContainer {
    text-align: center;
}

.sectionDivider {
    display: inline-flex;
    width: 30%;
    justify-content: space-between;
}

.starIcon {
    width: 0.7em;
}

/* Only hide on desktop & tablets */
@media only screen and (min-width: 600px) {
    .topDivider {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .bottomDivider {
        display: none;
    }

    .resultDivider {
        display: flex;
        justify-content: center;
        width: 80%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}