/* default color for states not supported by BallotTrax */
.allStates {
    fill: #bdbdbd;
}

.stateSupport {
    fill: #267e4b;
}

.countySupport {
    fill: #75bc93;
}

/* color and width of borders between states */
.borders {
    stroke: #ffffff;
    stroke-width: 1;
}

/* color and width of lines around Alaska and Hawaii */
.separator1 {
    stroke: #b0b0b0;
    stroke-width: 2;
}

.hover {
    fill: #d4cc00;
}

/* State abbreviations: Class name "tag" is for abbreviations that don't fit on the
   state. For those abbreviations, "label" is the class for the rectangle background */
.abbreviationRegular, .stateSupport .abbreviationRegular.tag, .countySupport .abbreviationRegular.tag {
    fill: #000000;
    font-size: 17px;
    text-transform: uppercase;
}

/* Only Hawaii */
.abbreviationSmall {
    fill: #000000;
    font-size: 14px;
    text-transform: uppercase;
}

.abbreviationRegular.tag {
    fill: #eeeeee;
}

.label {
    fill: none;
}

.stateSupport .label {
    fill: #267e4b;
}

.countySupport .label {
    fill: #75bc93;
}

.hover .label {
    fill: #d4cc00;
    fill-opacity: 1;
    stroke: #ffffff;
    stroke-width: 1.25;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
    stroke-dasharray: none;
}

.linePath {
    fill: none;
    stroke: #78909C;
    stroke-width: 1.25;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-opacity: 1;
    stroke-dasharray: none;
}