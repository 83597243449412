/* Header: Grid Row 1 */
.header {
    grid-row: 1;
    background-color: #eeeeee;
    border-bottom: 5px solid #0185bb;
}

.h1ImageOnly {
    margin: 0;
}

.ballotTraxLogo {
    width: 200px;
}