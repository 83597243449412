.tooltip {
    position: absolute;
    background-color: #f9f9f9;
    color: #03314c;
    border: #8f8f8f solid thin;
    box-shadow: 2px 2px 5px #8f8f8f;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    width: fit-content;
    max-width: 40rem;
    border-radius: 10px;
    padding: 1em;
    z-index: 10;
}

.tooltip > p {
    margin: 0.5em 0;
}

.tooltip a {
    color: #03314c;
}

.tooltipHeading {
    text-transform: uppercase;
    font-weight: 700;
    color: #af1017; /* ballot-red */
}

.multi-columns {
    display: flex;
    justify-content: space-between;
}

.countyList {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0.5em;
    padding-inline-start: 0;
    line-height: 1.5em;
    margin-right: 0.5em;
}

.countyList li {
    white-space: nowrap;
}

.note {
    font-style: italic;
    text-align: center;
}