.countySearchForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    align-self: center;
}

.countySearch {
    width: 80vw;
    font-size: 16px;
    padding: 0.3em 1em;
    margin: 1em;
}

.results {
    list-style-type: none;
    padding-left: 0;
    font-size: 12px;
}

.listItem {
    padding: 0.2em;
}

a.countySearchLink:link {
    color: #e6e6e6;
}

a.countySearchLink:visited {
    color: #a3c9d9;
}

a.countySearchLink:hover {
    font-weight: 700;
}

/* Only on desktop & tablets */
@media only screen and (min-width: 600px) {
    .countySearchForm {
        border: none;
        width: 950px;
    }

    .countySearch {
        width: 435px;
    }

    .col2 {
        columns: 2;
        column-fill: balance;
    }

    .col3 {
        columns: 3;
        column-fill: balance;
    }
}