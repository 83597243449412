html {
  height: 100%;
}

.container {
  display: grid; /* row 1 = .header, row 2 = .main, row 3 = .footer */
  height: 100vh;
  grid-template-rows: 60px 1fr minmax(35px, 40px);
}

/* Main: Grid Row 2 */
.main {
  grid-row: 2;
  background-color: #022133;  /* darker blue */
  color: #ffffff;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  text-align: center;
}

.h2Wmb {
  font-family: 'Noto Sans KR', sans-serif;
  display: block;
  font-size: 2.2em;
  font-weight: 500;
  margin: 0.5rem 0 1rem 0;
}

.subHead {
  font-family: 'Noto Sans KR', sans-serif;
  margin-top: 0;
}

.mapContainer {
  display: none;
}

/* Map: Only on desktop & tablets */
@media only screen and (min-width: 600px) {
  .h2Wmb {
    margin-bottom: 0.7rem;
  }

  .mapContainer {
    display: flex;
    flex-direction: column;
  }

  .mapInstructions {
    font-size: 14px;
    font-weight: 500;
    margin: 0.8rem 0 0 0;
  }
}
