.modalTitle {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;
    padding: 1em;
    background-color: #002840;
    color: white;
    text-transform: uppercase;
}

.privacyDisclosure {
    padding: 1em 2em;
    text-align: left;
    max-height: 80vh;
    line-height: 1.4em;
    color: #03314c;
    font-size: 14px;
    overflow: auto;
}