.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.content {
  z-index: 110;
  background: white;
  border-radius: 6px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  width: 35em;
}

@media (max-width: 768px) {
  .content {
    width: 90%;
  }
}

.background {
  position: absolute;
  z-index: 105;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #191919;
  opacity: 0.8;
}

